<template>
    <CContainer>
        <CCard>
            <validation-observer ref="observer" v-slot="{ handleSubmit }">
                <b-form @submit.stop.prevent="handleSubmit(submit)">
                    <CCardHeader>
                        <h4 class="m-0">{{ $t('pages.content.accountManagement.headers.edit') }}</h4>
                    </CCardHeader>
                    <CCardBody>
                        <b-overlay :show="loading" id="overlay-background" variant="white" opacity="0.85" blur="2px">
                            <validation-provider
                                :name="$t('pages.content.accountManagement.form.name', { language: $t('generally.languages.de') })"
                                :rules="{ required: true, min: 3, max: 32 }"
                                v-slot="validationContext"
                            >
                                <b-form-group
                                    label-cols-sm="12"
                                    label-cols-md="3"
                                    :label="$t('pages.content.accountManagement.form.name', { language: $t('generally.languages.de') })"
                                >
                                    <b-form-input
                                        v-model="form.name.de"
                                        maxlength="32"
                                        type="text"
                                        :state="getValidationState(validationContext)"
                                        aria-describedby="name-feedback-de"
                                    />
                                    <b-form-invalid-feedback id="name-feedback-de">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                </b-form-group>
                            </validation-provider>
                            <validation-provider
                                :name="$t('pages.content.accountManagement.form.name', { language: $t('generally.languages.en') })"
                                :rules="{ required: true, min: 3, max: 32 }"
                                v-slot="validationContext"
                            >
                                <b-form-group
                                    label-cols-sm="12"
                                    label-cols-md="3"
                                    :label="$t('pages.content.accountManagement.form.name', { language: $t('generally.languages.en') })"
                                >
                                    <b-form-input
                                        v-model="form.name.en"
                                        maxlength="32"
                                        type="text"
                                        :state="getValidationState(validationContext)"
                                        aria-describedby="name-feedback-en"
                                    />
                                    <b-form-invalid-feedback id="name-feedback-en">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                </b-form-group>
                            </validation-provider>
                            <validation-provider
                                :name="$t('pages.content.accountManagement.form.description', { language: $t('generally.languages.de') })"
                                :rules="{ required: true, max: 2048 }"
                                v-slot="validationContext"
                            >
                                <b-form-group
                                    label-cols-sm="12"
                                    label-cols-md="3"
                                    :label="$t('pages.content.accountManagement.form.description', { language: $t('generally.languages.de') })"
                                >
                                    <b-form-textarea
                                        v-model="form.description.de"
                                        maxlength="2048"
                                        type="text"
                                        :state="getValidationState(validationContext)"
                                        aria-describedby="description-feedback-de"
                                    />
                                    <b-form-invalid-feedback id="description-feedback-de">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                </b-form-group>
                            </validation-provider>
                            <validation-provider
                                :name="$t('pages.content.accountManagement.form.description', { language: $t('generally.languages.en') })"
                                :rules="{ required: true, max: 2048 }"
                                v-slot="validationContext"
                            >
                                <b-form-group
                                    label-cols-sm="12"
                                    label-cols-md="3"
                                    :label="$t('pages.content.accountManagement.form.description', { language: $t('generally.languages.en') })"
                                >
                                    <b-form-textarea
                                        v-model="form.description.en"
                                        maxlength="2048"
                                        type="text"
                                        :state="getValidationState(validationContext)"
                                        aria-describedby="description-feedback-en"
                                    />
                                    <b-form-invalid-feedback id="description-feedback-en">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                </b-form-group>
                            </validation-provider>
                            <validation-provider
                                :name="$t('pages.vdi.apps.form.image').toString()"
                                :rules="{ required: false, max: 255 }"
                                v-slot="validationContext"
                            >
                                <b-form-group
                                    label-cols-sm="12"
                                    label-cols-md="3"
                                >
                                    <template #label>
                                        {{ $t('pages.content.accountManagement.form.image') }}
                                        <font-awesome-icon icon="info-circle" v-b-popover.hover.top="$t('pages.content.accountManagement.form.hints.image')"/>
                                    </template>
                                    <b-input-group>
                                        <b-input-group-prepend>
                                            <b-input-group-text class="bg-white">
                                                <img :src="form.image" v-show="picture" width="20" height="20" @load="picture = true" @error="picture = false">
                                                <font-awesome-icon :icon="['fas', 'user-lock']" v-show="!picture" class="text-orange"/>
                                            </b-input-group-text>
                                        </b-input-group-prepend>
                                        <b-form-input
                                            type="url"
                                            name="image"
                                            maxlength="255"
                                            :state="getValidationState(validationContext)"
                                            v-model="form.image"
                                            :placeholder="$t('pages.vdi.apps.form.image.placeholder')"
                                            aria-describedby="image-feedback"
                                        />
                                        <b-form-invalid-feedback id="image-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                    </b-input-group>
                                </b-form-group>
                            </validation-provider>
                            <validation-provider
                                :name="$t('pages.content.accountManagement.form.instancesExpireAt')"
                                :rules="{ required: true }"
                                v-slot="validationContext"
                            >
                                <b-form-group
                                    label-cols-sm="12"
                                    label-cols-md="3"
                                    :label="$t('pages.content.accountManagement.form.instancesExpireAt')"
                                >
                                    <b-form-datepicker
                                        v-model="form.expireDate"
                                        :state="getValidationState(validationContext)"
                                        aria-describedby="expire-feedback"
                                        hide-header
                                        v-bind="$t('modules.datepicker') || {}"
                                        :date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit' }"
                                        :min="new Date()"
                                    ></b-form-datepicker>
                                    <b-form-invalid-feedback id="expire-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>

                                </b-form-group>
                                <b-form-group
                                    label-cols-sm="12"
                                    label-cols-md="3">
                                    <b-form-timepicker
                                        v-model="form.expireTime"
                                        locale="de"
                                        no-close-button
                                        hide-header
                                        aria-describedby="expire-feedback"
                                        :state="getValidationState(validationContext)"
                                        v-bind="$t('modules.timepicker') || {}"
                                    ></b-form-timepicker>
                                    <b-form-invalid-feedback id="expire-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                </b-form-group>
                            </validation-provider>
                            <b-form-group
                                label-cols-sm="12"
                                label-cols-md="3"
                            >
                                <template #label>
                                    {{ $t('pages.content.accountManagement.form.public') }}
                                    <font-awesome-icon icon="info-circle" v-b-popover.hover.top="$t('pages.content.accountManagement.form.hints.public')"/>
                                </template>
                                <b-form-select v-model="form.public">
                                    <b-form-select-option :value="false">{{ $t('pages.content.accountManagement.values.public.no') }}</b-form-select-option>
                                    <b-form-select-option :value="true">{{ $t('pages.content.accountManagement.values.public.yes') }}</b-form-select-option>
                                </b-form-select>
                            </b-form-group>
                            <b-form-group>
                                <template #label>
                                    {{ $t('pages.content.accountManagement.form.instanceInfoTemplate') }} (<CLink @click="editorToggle = !editorToggle"><font-awesome-icon icon="code"/></CLink>)
                                </template>
                                <vue-editor
                                    v-if="editorToggle"
                                    v-model="form.instanceInfoTemplate"
                                ></vue-editor>
                                <vue-codeditor style="font-size: 14px" v-else v-model="form.instanceInfoTemplate" mode="html" theme="monokai"/>
                            </b-form-group>
                        </b-overlay>
                    </CCardBody>
                    <CCardFooter class="d-flex justify-content-end">
                        <b-button type="submit" variant="orange">{{ $t("buttons.save") }}</b-button>
                    </CCardFooter>
                </b-form>
            </validation-observer>
        </CCard>
    </CContainer>
</template>

<script>
import accountService from "../../../services/accountService";
import {VueEditor} from "vue2-editor";

export default {
    name: "EditAccount",
    props: ["id"],
    components: {
        VueEditor
    },
    data: () => ({
        fields: [],
        form: {
            name: {
                de: '',
                en: ''
            },
            description: {
                de: '',
                en: ''
            },
            image: '',
            public: false,
            expireTime: '',
            expireDate: ''
        },
        account: {},
        picture: false,
        loading: false,
        editorToggle: false
    }),
    mounted() {
        accountService.get(this.id).then(response => {
            response.data.name = JSON.parse(response.data.name)
            response.data.description = JSON.parse(response.data.description)
            response.data.expireDate = this.$moment(response.data.instancesExpireAt).format('YYYY-MM-DD');
            response.data.expireTime = this.$moment(response.data.instancesExpireAt).format('H:mm');
            this.form = Object.assign(this.form, response.data);
        }).catch();
    },
    methods: {
        getValidationState({dirty, validated, valid = null}) {
            return dirty || validated ? valid : null;
        },
        submit() {
            if (!this.loading) {
                this.loading = true;
                let data = Object.assign({}, this.form)
                data.name = JSON.stringify(data.name)
                data.description = JSON.stringify(data.description)
                data.instancesExpireAt = this.$moment(this.form.expireDate).format('YYYY-MM-DD') + ' ' + this.form.expireTime;
                delete data.expireTime;
                delete data.expireDate;
                accountService.edit(data).then(() => {
                    this.$toastr.s(this.$t('pages.content.accountManagement.messages.success.edited'));
                    this.$router.push({name: 'ContentAccountManagementList'});
                    this.loading = false;
                }).catch(error => {
                    this.errorHandler(error);
                    this.loading = false;
                });
            }
        }
    }
};
</script>

<style scoped>

</style>
